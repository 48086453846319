import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import Cookies from 'js-cookie'

// the time in days after which the disable-newsletter cookie will expire
const COOKIE_EXPIRES = 14

export const useModalNewsletter = (modalNewsletter: {
  triggerRouteNumber?: number
  timeToTrigger?: number
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [routeVisited, setRouteVisited] = useState(0)
  const router = useRouter()

  const triggerRouteNumber = modalNewsletter.triggerRouteNumber ?? 1
  const timeToTrigger = modalNewsletter.timeToTrigger ?? 8

  const incrementRouteVisited = () => {
    setRouteVisited(routeVisited + 1)
  }

  useEffect(() => {
    router.events.on('routeChangeComplete', incrementRouteVisited)
    return () => {
      router.events.off('routeChangeComplete', incrementRouteVisited)
    }
  }, [routeVisited])

  useEffect(() => {
    if (routeVisited === triggerRouteNumber) {
      setTimeout(() => {
        handleOpen()
      }, timeToTrigger * 1000)
      router.events.off('routeChangeComplete', incrementRouteVisited)
    }
  }, [routeVisited])

  const handleOpen = () => {
    if (Cookies.get('disableNewsletter')) return
    return setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
    Cookies.set('disableNewsletter', 'true', {
      expires: COOKIE_EXPIRES,
    })
  }

  const openNewsletter = () => {
    setIsOpen(true)
    Cookies.set('disableNewsletter', 'false', {
      expires: COOKIE_EXPIRES,
    })
  }

  return { isOpen, handleClose, openNewsletter }
}
