import { createBaseImageProjection } from './createBaseImageProjection'
import { groq } from 'next-sanity'
import { createSectionBaseSiteListProjection } from '../sections/createSectionBaseSiteListProjection'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { createPerfMetricProjection } from './createPerfMetricsProjection'
export const createSitePageProjection = (localeId: string) => {
  const BASE_IMAGE_PROJECTION = createBaseImageProjection(localeId)
  const PERF_METRIC_PROJECTION = createPerfMetricProjection()
  const SIMILAR_SITES_SEARCH = groq`[
    ...similarSites[]->,
    ...*[_type == 'site' && _id != ^._id && !(defined(^.similarSites) && _id in ^.similarSites[]._ref)]
      | score(
        boost(ECPlatform._ref == ^.ECPlatform._ref, 0.5),
        boost(FEFramework._ref == ^.FEFramework._ref, 0.5),
        boost(CMS._ref == ^.CMS._ref, 0.5)
      )
      | order(_score desc)
  ][0...3]`
  return groq`{
      "type":_type,
      "showFooter": true,
      "pageBuilder": [
        {
          "_type": "sectionSiteDetails",
          "_key": "sectionSiteDetails" + _id,
          name,
          url,
          perfMetric${PERF_METRIC_PROJECTION},
          tags[]->{
            name,
            "path":path.current,
          },
          ECPlatform->{
            name,
            "path":path.current,
            avatar${BASE_IMAGE_PROJECTION},
          },
          FEFramework->{
            name,
            "path":path.current,
            avatar${BASE_IMAGE_PROJECTION},

          },
          CMS->{
            name,
            "path":path.current,
            avatar${BASE_IMAGE_PROJECTION},

          },
          tools[]->{
            name,
            "path":path.current,
            avatar${BASE_IMAGE_PROJECTION},
            toolCategory->{
                name,
            }
          },
           "highPerformance":count([
              perfMetric.mobile.first_input_delay,
              perfMetric.mobile.cumulative_layout_shift,
              perfMetric.mobile.first_contentful_paint,
              perfMetric.mobile.largest_contentful_paint,
            ][percentiles.p75 < histogram[0].end]) >= 3,
          desktopMediaList[]${BASE_IMAGE_PROJECTION},
        },
        ${createSectionBaseSiteListProjection(
          localeId,
          SIMILAR_SITES_SEARCH,
          true,
        )}
      ],
        "seo":{
            "metaTitle":"The Headless Club - " + name ,
            "ogTitle":"The Headless Club - " + name ,
            ${coalesceLocaleField(
              'shortDescription',
              localeId,
              'metaDescription',
            )},
            ${coalesceLocaleField(
              'shortDescription',
              localeId,
              'ogDescription',
            )},
           "ogImage":desktopMediaList[0]${BASE_IMAGE_PROJECTION},
          }
    }`
}
