import React, { FC } from 'react'

export const DotGreen: FC = () => {
  return (
    <svg
      width="7"
      height="6"
      viewBox="0 0 7 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="3.5" cy="3" r="3" fill="url(#paint0_linear_450_1338)" />
      <defs>
        <linearGradient
          id="paint0_linear_450_1338"
          x1="3.5"
          y1="0"
          x2="3.5"
          y2="6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#89F384" />
          <stop offset="1" stopColor="#3DEEE3" />
        </linearGradient>
      </defs>
    </svg>
  )
}
