import { LocaleId } from '@the-headless-club/configs'
import { groq } from 'next-sanity'
import { createLinkProjection } from './createLinkProjection'

export const createPortableTextProjection = (localeId: LocaleId) => {
  const LINK_PROJECTION = createLinkProjection(localeId)

  return groq`{
    ...,
    markDefs[]{
      ...,
      _type == "link" => {
        _key,
        _type,
        ...${LINK_PROJECTION},
      }
    }
  }`
}
