import { LocaleId } from '@the-headless-club/configs'
import { groq } from 'next-sanity'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { createBaseImageProjection } from './createBaseImageProjection'

export const createSEOProjection = (localeId: LocaleId) => {
  const BASE_IMAGE_PROJECTION = createBaseImageProjection(localeId)

  return groq`{  
        ${coalesceLocaleField('metaTitle', localeId)},
        ${coalesceLocaleField('metaDescription', localeId)},
        ${coalesceLocaleField('ogTitle', localeId)},
        ${coalesceLocaleField('ogDescription', localeId)},
        ogImage${BASE_IMAGE_PROJECTION},

    }`
}
