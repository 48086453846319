import { createToolsLandingPageProjection } from './projections/createToolsLandingPageProjection'
import { getLocaleRegionIdFromPath } from '@the-headless-club/utils'
import { groq } from 'next-sanity'
import { getClient } from './getClient'
import { PageBuilderSection, PageResponse } from '@the-headless-club/models'
import { createDefaultPageProjection } from './projections/createDefaultPageProjection'
import { createToolPageProjection } from './projections/createToolPageProjection'
import { createTagPageProjection } from './projections/createTagPageProjection'
import { createSitePageProjection } from './projections/createSitePageProjection'

export const getRouteByPath = async (
  path: string,
  localeRegionString: string,
  preview = false,
): Promise<PageResponse<PageBuilderSection[]>> => {
  const [regionId, localeId] = getLocaleRegionIdFromPath(localeRegionString)
  const DEFAULT_PAGE_PROJECTION = createDefaultPageProjection(
    regionId,
    localeId,
  )
  const TOOL_PAGE_PROJECTION = createToolPageProjection(localeId)
  const TAG_PAGE_PROJECTION = createTagPageProjection(localeId)
  const SITE_PAGE_PROJECTION = createSitePageProjection(localeId)
  const TOOLS_LANDING_PAGE_PROJECTION =
    createToolsLandingPageProjection(localeId)

  const query = groq`*[!(_id in path('drafts.**')) && defined(path.current) && path.current == "${path}"][0]{
    "page": select(
      _type == "route" =>${DEFAULT_PAGE_PROJECTION},
      _type == "tool" =>${TOOL_PAGE_PROJECTION},
      _type == "tag" =>${TAG_PAGE_PROJECTION},
      _type == "site" =>${SITE_PAGE_PROJECTION},
      _type == "toolsLandingPage" =>${TOOLS_LANDING_PAGE_PROJECTION},
    )
  }.page`

  return await getClient(preview).fetch(query)
}
