export const config = {
  dataset: process.env.SANITY_STUDIO_API_DATASET || '',
  projectId: process.env.SANITY_STUDIO_API_PROJECT_ID || '',
  useCdn: process.env.NODE_ENV === 'production',
  /**
   * Use a UTC date string
   * https://www.sanity.io/help/js-client-api-version
   */
  apiVersion: '2022-03-24',
}
