import React, { FC } from 'react'
import ReactModal from 'react-modal'
import { Overlay } from './Overlay'
import { Content } from './Content'

export type ModalOverlayStyle = 'transparent' | 'blur' | 'light' | 'dark'

export type ModalContentPosition =
  | 'center'
  | 'bottom'
  | 'bottom-right'
  | 'left'
  | 'right'
  | 'top'
export type ModalContentVariant = 'default' | 'white-rounded' | 'rounded'
export type ModalContentSize =
  | 'intrinsic'
  | 'stretch'
  | 'stretch-x'
  | 'stretch-y'

export type ModalProps = ReactModal.Props & {
  // Content
  variant?: ModalContentVariant
  position?: ModalContentPosition
  size?: ModalContentSize
  // Overlay
  overlayCloseButton?: boolean
  overlayCloseButtonLabel?: string
  overlayVariant?: ModalOverlayStyle
  // Animations
  enterAnimation?: 'fade-in' // TODO: | 'slideFromBottom'
  exitAnimation?: 'fade-out' // TODO: | 'slideToBottom'

  // General props - from 'react-modal'
  //
  // isOpen: boolean
  // onBeforeOpen: () => void
  // onAfterOpen: () => void
  // onBeforeClose: () => void
  // onAfterClose: () => void
  // preventScroll: boolean
  // onRequestClose: () => void
}

export const Modal: FC<ModalProps> = ({
  variant = 'default',
  position = 'center',
  size = 'intrinsic',
  overlayCloseButton,
  overlayCloseButtonLabel,
  overlayVariant = 'dark',
  isOpen,
  onRequestClose,
  children,
  ...reactModalProps
}) => {
  const transitionTime = 166

  const contentProps = { variant, position, size }
  const overlayProps = {
    overlayCloseButton,
    overlayCloseButtonLabel,
    overlayVariant,
    transitionTime,
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayElement={(props, contentElement) => (
        <Overlay
          {...props}
          {...overlayProps}
          hasPadding={position === 'center' && size === 'intrinsic'}
        >
          {contentElement}
        </Overlay>
      )}
      contentElement={(props, children) => (
        <Content {...props} {...contentProps}>
          {children}
        </Content>
      )}
      className={'Content'}
      overlayClassName={'Overlay'}
      closeTimeoutMS={transitionTime}
      {...reactModalProps}
    >
      {children}
    </ReactModal>
  )
}
