import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@the-headless-club/configs'
import { createPortableTextProjection } from '../projections/createPortableTextProjection'

export const createSectionRichTextProjection = (localeId: LocaleId) => {
  const PORTABLE_TEXT_PROJECTION = createPortableTextProjection(localeId)
  return groq`{
        _key,
        _type,
        ${coalesceLocaleField('title', localeId)},
        ${coalesceLocaleField(
          'content',
          localeId,
        )}[]${PORTABLE_TEXT_PROJECTION},

      }
  `
}
