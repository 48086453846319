import { FC } from 'react'

export const CheckIcon: FC = () => {
  return (
    <svg width={16} height={16} viewBox="0 0 512 512">
      <g
        transform="translate(0,512) scale(0.1,-0.1)"
        fill="currentColor"
        stroke="none"
      >
        <path
          d="M4468 4401 c-36 -10 -88 -31 -115 -46 -32 -18 -446 -425 -1245 -1224
  l-1198 -1196 -532 531 c-293 292 -555 546 -581 563 -163 110 -396 111 -563 3
  -174 -113 -264 -327 -221 -529 34 -158 -4 -114 824 -944 509 -510 772 -766
  808 -788 108 -65 264 -87 389 -55 146 38 67 -37 1582 1478 896 896 1411 1418
  1428 1447 52 92 69 156 69 269 0 155 -42 259 -146 363 -127 127 -320 176 -499
  128z"
        />
      </g>
    </svg>
  )
}
