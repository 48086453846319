export const Error = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.80056 6.8484H6.19612L6.13422 3.33826H6.86246L6.80056 6.8484Z"
        fill="#F82E47"
      />
      <path
        d="M6.86246 8.51972C6.77022 8.61439 6.65006 8.66173 6.50198 8.66173C6.3539 8.66173 6.23253 8.61439 6.13786 8.51972C6.04319 8.42505 5.99585 8.30368 5.99585 8.1556C5.99585 8.0051 6.04319 7.88251 6.13786 7.78784C6.23253 7.69316 6.3539 7.64583 6.50198 7.64583C6.65006 7.64583 6.77022 7.69316 6.86246 7.78784C6.95713 7.88251 7.00447 8.0051 7.00447 8.1556C7.00447 8.30368 6.95713 8.42505 6.86246 8.51972Z"
        fill="#F82E47"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 6C11.5 8.76142 9.26142 11 6.5 11C3.73858 11 1.5 8.76142 1.5 6C1.5 3.23858 3.73858 1 6.5 1C9.26142 1 11.5 3.23858 11.5 6ZM10.7 6C10.7 8.3196 8.8196 10.2 6.5 10.2C4.1804 10.2 2.3 8.3196 2.3 6C2.3 3.6804 4.1804 1.8 6.5 1.8C8.8196 1.8 10.7 3.6804 10.7 6Z"
        fill="#F82E47"
      />
    </svg>
  )
}
