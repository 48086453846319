import { styled } from '@the-headless-club/styles'
import { FC, useState, ReactElement } from 'react'

const Root = styled('div', {
  overflow: 'hidden',
})

export type Props = {
  allowMultipleActiveItems?: boolean
  initiallyOpenedItemsIds?: number[]
  children: (
    handleRequestOpen: (itemId: number) => void,
    handleRequestClose: (itemId: number) => void,
    isActive: (itemId: number) => boolean,
  ) => ReactElement[]
  className?: string
}

export const AccordionItemsList: FC<Props> = ({
  allowMultipleActiveItems = true,
  initiallyOpenedItemsIds = [],
  children,
  className,
}) => {
  const [activeItemsIds, setActiveItemsIds] = useState<number[]>(
    initiallyOpenedItemsIds,
  )

  const handleRequestOpen = (itemId: number) => {
    const result = allowMultipleActiveItems
      ? activeItemsIds.includes(itemId)
        ? [...activeItemsIds]
        : [...activeItemsIds, itemId]
      : [itemId]
    return setActiveItemsIds(result)
  }

  const handleRequestClose = (itemId: number) => {
    const result = allowMultipleActiveItems
      ? [...activeItemsIds.filter((_) => _ !== itemId)]
      : []

    return setActiveItemsIds(result)
  }

  const isActive = (itemId: number) => {
    return activeItemsIds.includes(itemId)
  }

  return (
    <Root className={className}>
      {children(handleRequestOpen, handleRequestClose, isActive)}
    </Root>
  )
}
