import { LocaleId } from '@the-headless-club/configs'
import { getLocaleRegionIdFromPath } from '@the-headless-club/utils'
import { groq } from 'next-sanity'
import { createPageBuilderProjection } from './projections/createPageBuilderProjection'
import { getClient } from './getClient'
import { PageBuilderSection, PageResponse } from '@the-headless-club/models'
import { createSitePageProjection } from './projections/createSitePageProjection'
import { createTagPageProjection } from './projections/createTagPageProjection'
import { createToolPageProjection } from './projections/createToolPageProjection'
import { createToolsLandingPageProjection } from './projections/createToolsLandingPageProjection'

export const createPageByDocumentIdQuery = (
  documentId: string,
  localeId: LocaleId,
) => {
  const PAGE_BUILDER_PROJECTION = createPageBuilderProjection(localeId)
  const TOOL_PAGE_PROJECTION = createToolPageProjection(localeId)
  const TOOLS_LANDING_PAGE_PROJECTION =
    createToolsLandingPageProjection(localeId)
  const TAG_PAGE_PROJECTION = createTagPageProjection(localeId)
  const SITE_PAGE_PROJECTION = createSitePageProjection(localeId)

  return groq`*[_id == "${documentId}"][0]{
    "page": select(
      _type == 'page' => {
        showFooter,
        title,
        pageBuilder[]${PAGE_BUILDER_PROJECTION}
      },
      _type == "site" =>${SITE_PAGE_PROJECTION},
      _type == "tool" =>${TOOL_PAGE_PROJECTION},
      _type == "toolsLandingPage" =>${TOOLS_LANDING_PAGE_PROJECTION},
      _type == "tag" =>${TAG_PAGE_PROJECTION},
    )
  }.page`
}

export const getPageByDocumentId = async (
  documentId: string,
  localeRegionString: string,
  preview = false,
): Promise<PageResponse<PageBuilderSection[]>> => {
  const [, localeId] = getLocaleRegionIdFromPath(localeRegionString)

  const query = createPageByDocumentIdQuery(documentId, localeId)

  const pageData = await getClient(preview).fetch(query)

  return pageData
}
