import { groq } from 'next-sanity'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { createSectionBaseSiteListProjection } from '../sections/createSectionBaseSiteListProjection'

export const createTagPageProjection = (localeId: string) => {
  const SITE_LIST_FILTER =
    "*[_type=='site' && references(^._id)]  | order(orderRank desc) "

  return groq`{
      "type":_type,    
      "showFooter": true,
      "pageBuilder": [
        {
          "_type": "sectionHeroLanding",
          "_key": "sectionHeroLanding" + _id,
          "totalCount":count(*[_type=='site' && references(^._id)]),
          "title":name,
        },
        ${createSectionBaseSiteListProjection(localeId, SITE_LIST_FILTER)}
      ],
      "seo":{
        "metaTitle":"The Headless Club - " + name ,
        "ogTitle":"The Headless Club - " + name ,
        ${coalesceLocaleField('shortDescription', localeId, 'metaDescription')},
        ${coalesceLocaleField('shortDescription', localeId, 'ogDescription')},
      }
    }`
}
