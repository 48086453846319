import { createSiteListProjection } from '../projections/createSiteListProjection'
import { groq } from 'next-sanity'
import { LocaleId } from '@the-headless-club/configs'

export const createSectionBaseSiteListProjection = (
  localeId: LocaleId,
  filter = "*[_type=='site'] | order(orderRank desc)",
  hasTitle = false,
  additionalFilter = '',
) => {
  return groq`{
    "_type": "sectionBaseSiteList",
    "_key": "sectionBaseSiteList" + _id,
    "hasTitle":${hasTitle},
    "items": ${createSiteListProjection(localeId, filter, additionalFilter)}
  }`
}
