import React, { FC } from 'react'

export const DotGrey: FC = () => {
  return (
    <svg
      width="7"
      height="6"
      viewBox="0 0 7 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="3.5" cy="3" r="3" fill="#DFDBD4" />
    </svg>
  )
}
