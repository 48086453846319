import React, { FC } from 'react'
import { styled, useMediaQuery } from '@the-headless-club/styles'
import { Button, Modal } from '@the-headless-club/components'
import { NewsletterForm } from './NewsletterForm'

const Content = styled('div', {
  display: 'grid',
  gap: '$13',
  overflow: 'hidden',
  padding: '20px',
  pointerEvents: 'auto',
  backgroundColor: '$alabaster',
  borderTop: '1px solid $black',

  '@md': {
    minWidth: '500px',
    maxWidth: '550px',
    border: '1px solid $black',
    borderRadius: '$r2',
  },
})

const ModalHeader = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 60px',
  gap: '$4',

  '@md': {
    gap: '$6',
  },
})

const StyledCloseButton = styled(Button, {
  projectFont: 'body02',
  color: '$black',
  textTransform: 'uppercase',
  height: 'min-content',
  cursor: 'pointer',

  '&:focus-visible': {
    outline: '$black solid 2px;',
    outlineOffset: '3px',
  },

  '&:hover': {
    opacity: 0.6,
    transition: 'opacity 0.3s',
  },
})

const Heading = styled('p', {
  projectFont: 'body05',
})

export const ModalNewsletter: FC<{
  newsletterHeading?: string
  isOpen: boolean
  handleClose: () => void
}> = ({ isOpen, handleClose, newsletterHeading }) => {
  const matchesMd = useMediaQuery('md')

  if (!newsletterHeading) return null

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className="Modal"
      preventScroll={true}
      shouldCloseOnOverlayClick={false}
      position={matchesMd ? 'bottom-right' : 'bottom'}
      size={matchesMd ? 'intrinsic' : 'stretch-x'}
      variant={matchesMd ? 'white-rounded' : 'default'}
      bodyOpenClassName={null}
      overlayVariant={'transparent'}
      overlayClassName={'Overlay NewsletterModal__Overlay'}
    >
      <Content>
        <ModalHeader>
          {newsletterHeading && <Heading>{newsletterHeading}</Heading>}
          <StyledCloseButton onClick={handleClose}>Close</StyledCloseButton>
        </ModalHeader>
        <NewsletterForm />
      </Content>
    </Modal>
  )
}
