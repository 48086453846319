import { groq } from 'next-sanity'
import { createBaseImageProjection } from './createBaseImageProjection'
import { createSEOProjection } from './createSEOProjection'

export const createToolsLandingPageProjection = (localeId: string) => {
  const BASE_IMAGE_PROJECTION = createBaseImageProjection(localeId)

  return groq`{
      "type":_type,
      "showFooter": true,
      "pageBuilder": [
        {
          "_type": "sectionToolsLandingPage",
          "_key": "sectionToolsLandingPage" + _id,
          "title":"LP",
          "tools":*[_type=="tool"]{
            name,
            "path":path.current,
            _id,
            url,
            "siteCount":count(*[_type=='site' && references(^._id)]),
            avatar${BASE_IMAGE_PROJECTION},
            logo${BASE_IMAGE_PROJECTION},
            toolCategory->{
                name,
                "path":path.current
            },
          } | order(siteCount desc)[siteCount > 0],
          "filters":[
            {
                "label":"Category",
                "name":"category",
                "type": "dropdown",
                "values":*[_type=="toolCategory"]{
                    name,
                }
            }
          ]

        },
      ],
      seo${createSEOProjection(localeId)},

    }`
}
