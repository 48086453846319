import { groq } from 'next-sanity'
import { LocaleId } from '@the-headless-club/configs'
import { createSiteCardProjection } from '../projections/createSiteCardProjection'

export const createSiteListProjection = (
  localeId: LocaleId,
  filter = "*[_type=='site'] | order(orderRank desc)",
  additionalFilter = '',
) => {
  return groq`${filter}${createSiteCardProjection(localeId)}${
    additionalFilter ? additionalFilter : ''
  }`
}
