import { groq } from 'next-sanity'
import { LocaleId } from '@the-headless-club/configs'
import { createSectionBaseSiteListProjection } from '../sections/createSectionBaseSiteListProjection'
import { createSectionSiteListProjection } from '../sections/createSectionSiteListProjection'
import { createSectionRichTextProjection } from '../sections/createSectionRichTextProjection'
import { createSectionToolsLandingPageProjection } from '../sections/createSectionToolsLandingPageProjection'
import { createSectionInfoAboutUsProjection } from '../sections/createSectionInfoAboutUsProjection'
import { createSectionHeroPromoTextProjection } from '../sections/createSectionHeroPromoTextProjection'

export const createPageBuilderProjection = (localeId: LocaleId) => groq`{
  _type == 'SectionBaseSiteList' =>  ${createSectionBaseSiteListProjection(
    localeId,
  )},

  _type == 'sectionSiteList' =>  ${createSectionSiteListProjection(localeId)},

  _type == 'sectionRichText' =>  ${createSectionRichTextProjection(localeId)},
  _type == 'sectionToolsLandingPage' =>  ${createSectionToolsLandingPageProjection(
    localeId,
  )},
  _type == 'sectionInfoAboutUs' =>  ${createSectionInfoAboutUsProjection(
    localeId,
  )},
  _type == 'sectionHeroPromoText' =>  ${createSectionHeroPromoTextProjection(
    localeId,
  )},
}`
