import { styled } from '@the-headless-club/styles'
import React from 'react'

export const StyledClickable = styled('button', {
  background: 'none',
  variants: {
    appearance: {
      outline: {
        projectFont: 'body01',
        border: '1px solid $almost_black',
        color: '$almost_black',
        borderRadius: '$rMax',
        height: '$buttonHeightM',
        display: 'inline-flex',
        padding: '0 $8',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        '&:focus-visible': {
          outline: '$black solid 1px;',
          outlineOffset: '4px',
        },
        '@lg': {
          '&:hover': {
            opacity: '0.5',
          },
        },
      },
      containedWhiteRound: {
        projectFont: 'body03',
        color: '$black',
        backgroundColor: '$black_005',
        borderRadius: '$rMax',
        height: '$buttonHeightS',
        display: 'inline-flex',
        padding: '0 $6',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        '&:focus-visible': {
          outline: '$black solid 1px;',
          outlineOffset: '4px',
        },
        transition: 'color 200ms, background 200ms',
        '@lg': {
          '&:hover': {
            color: '$white',
            backgroundColor: '$almost_black',
          },
        },
      },
      containedBlackRound: {
        projectFont: 'body01',
        background: '$almost_black',
        color: '$white',
        borderRadius: '$rMax',
        height: '$buttonHeightM',
        display: 'inline-flex',
        padding: '0 $8',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        '&:focus-visible': {
          outline: '$black solid 1px;',
          outlineOffset: '4px',
        },
        '@lg': {
          '&:hover': {
            opacity: '0.5',
          },
        },
      },
      containedWhiteRect: {
        projectFont: 'body03',
        borderRadius: '$r1',
        color: '$black',
        backgroundColor: '$black_005',
        height: '$buttonHeightS',
        display: 'inline-flex',
        padding: '0 $6',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        '&:focus-visible': {
          outline: '$black solid 1px;',
          outlineOffset: '4px',
        },
        transition: 'color 200ms, background 200ms',
        '@lg': {
          '&:hover': {
            color: '$white',
            backgroundColor: '$almost_black',
          },
        },
      },
      bigWhiteRound: {
        projectFont: 'heading03',
        color: '$black',
        backgroundColor: '$white',
        border: '1px solid',
        height: '$buttonHeightL',
        borderRadius: '$rMax',
        display: 'inline-flex',
        padding: '$6 $8',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0 4px 4px rgba(0,0,0,0.25)',
        '&:focus-visible': {
          outline: '$black solid 1px;',
          outlineOffset: '4px',
        },
        '@lg': {
          '&:hover': {
            opacity: '0.5',
          },
        },
      },
      grid: {
        display: 'grid',
      },
      ellipse: {
        display: 'inline-flex',
        justifyItems: 'center',
        alignContent: 'center',
        gridTemplateColumns: 'auto 1fr',
        alignItems: 'center',
        justifyContent: 'center',
        gridGap: '$3',
        border: '1px solid',
        projectFont: 'body02',
        borderRadius: '50%',
        padding: '0 $6',
        height: '$9',
        zIndex: '$header',
        '@lg': {
          '&:hover': {
            opacity: '0.5',
          },
        },
      },
    },
    size: {
      large: {},
      medium: {},
      small: {},
    },
  },
  compoundVariants: [],
  '&:disabled': {
    pointerEvents: 'none',
  },

  '&:focus-visible': {
    outline: '$black solid 2px',
    outlineOffset: '2px',
  },
})

type Appearance =
  | 'outline'
  | 'containedWhiteRound'
  | 'containedWhiteRect'
  | 'bigWhiteRound'
  | 'containedBlackRound'
  | 'grid'
  | 'ellipse'
export type StyledClickableProps = {
  size?: 'large' | 'medium' | 'small'
  appearance?: Appearance
  tabIndex?: number
  disabled?: boolean
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  onFocus?: () => void
  onClick?: (event: React.MouseEvent | React.KeyboardEvent) => void
}
