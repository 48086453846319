import { FC, PropsWithChildren, useState } from 'react'
import { Site } from '@the-headless-club/models'
import { styled } from '@the-headless-club/styles'
import { useTranslation } from 'react-i18next'
import { GoToArrow } from '@the-headless-club/icons'
import { Link } from '../Link/Link'
import { Media } from '../Media/Media'
import { Button } from '../Button/Button'
import { HighPerformanceLabel } from '../PerformanceMetrics/HighPerformanceLabel'

const ProjectCard = styled('div', {
  gridColumn: 1,
  gridRow: 1,
  borderRadius: '$r2',
  overflow: 'hidden',
  display: 'grid',
  gap: '$8',
  position: 'relative',
})
const Name = styled('h3', {
  projectFont: 'heading02',
  color: '$almost_black',
})

const ToolsAndTags = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '$3',
})

const Tags = styled('div', {
  margin: '0 $4 0 0',
})

const MediaWrap = styled(Link, {
  display: 'grid',
  width: '100%',
  borderRadius: '$r2',
  overflow: 'hidden',
  position: 'relative',
  background: '$alabaster_01',
})

const OpenButton = styled(Link, {
  position: 'absolute',
  right: '$8',
  bottom: '$8',
  opacity: 0,
  visibility: 'hidden',
  color: '$white',
  background: '$black_02',
  '&:hover': {
    background: '$black_04',
  },
})

const ProjectCardWrap = styled('div', {
  display: 'grid',
  gap: '$8',
  alignSelf: 'start',
  '&:hover': {
    [`& ${OpenButton}`]: {
      opacity: 1,
      visibility: 'visible',
    },
  },
})

const CardHeader = styled('div', {
  display: 'grid',
  alignItems: 'center',
  gridAutoFlow: 'column',
  gridTemplateColumns: '1fr min-content',
  gridGap: '$8',
})

type SiteCardProps = PropsWithChildren<{
  item?: Site
  saveScroll?: boolean
  key?: string
  priority?: boolean
}>

export const SiteCard: FC<SiteCardProps> = ({ item, saveScroll, priority }) => {
  const { t } = useTranslation('site')
  const MAX_DISPLAY_TOOLS = 3
  const [showMoreTools, setShowMoreTools] = useState<boolean>(false)

  const setScrollPosition = () => {
    if (saveScroll) {
      sessionStorage.setItem('scrollPosition', '' + window.pageYOffset)
    }
  }

  return (
    <ProjectCardWrap>
      <ProjectCard>
        {item?.desktopMediaList && (
          <MediaWrap
            href={item.path}
            disabled={true}
            onClick={setScrollPosition}
            tabIndex={-1}
          >
            <Media
              {...item.desktopMediaList[0]}
              sizes={
                '(min-width: 1000px) 33vw ,(min-width: 740px) 50vw , 100vw'
              }
              priority={priority}
              hardcropRatio="landscape2"
              hardHotspot={[0, 0]}
            />
          </MediaWrap>
        )}
        <OpenButton href={item?.url} appearance="ellipse" target="_blank">
          <>
            {t('open')} <GoToArrow fill="white" />
          </>
        </OpenButton>
      </ProjectCard>
      <CardHeader>
        {item?.name && item?.path && (
          <Link href={item?.path} appearance="grid" onClick={setScrollPosition}>
            <Name>{item.name}</Name>
          </Link>
        )}

        {item?.perfMetric?.mobile && (
          <HighPerformanceLabel isHighPerformance={item.highPerformance} />
        )}
      </CardHeader>

      <ToolsAndTags>
        <Tags>
          {item?.tags && item?.tags.length > 0 && (
            <Link
              href={item.tags[0].path}
              appearance="containedWhiteRect"
              onClick={setScrollPosition}
            >
              {item.tags[0].name}
            </Link>
          )}
        </Tags>

        {item?.allTools &&
          item?.allTools.length > 0 &&
          item.allTools.slice(0, 3).map((tool, index) => (
            <Link
              href={tool?.path}
              key={`${item.name}-tool-${index}`}
              appearance="containedWhiteRound"
              onClick={setScrollPosition}
            >
              {tool?.name}
            </Link>
          ))}

        {item?.allTools &&
          item?.allTools?.length > MAX_DISPLAY_TOOLS &&
          !showMoreTools && (
            <Button
              onClick={() => setShowMoreTools(true)}
              appearance={'containedWhiteRound'}
            >
              ...
            </Button>
          )}
        {showMoreTools &&
          item?.allTools &&
          item?.allTools.slice(3).map((tool, index) => (
            <Link
              href={tool?.path}
              key={`${item.name}-additonal-tool-${index}`}
              appearance="containedWhiteRound"
              onClick={setScrollPosition}
            >
              {tool?.name}
            </Link>
          ))}
      </ToolsAndTags>
    </ProjectCardWrap>
  )
}
