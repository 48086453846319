import { groq } from 'next-sanity'
import { LocaleId } from '@the-headless-club/configs'
import { createBaseImageProjection } from './createBaseImageProjection'

export const createToolListProjection = (
  localeId: LocaleId,
  additionalFilter = '',
) => {
  const BASE_IMAGE_PROJECTION = createBaseImageProjection(localeId)

  return groq`{
    "filteredTools":*[_type=='tool']{
        name,
        "path":path.current,
        url,
        avatar${BASE_IMAGE_PROJECTION},
        logo${BASE_IMAGE_PROJECTION},
        toolCategory->{
            name,
        },
        "siteCount":count(*[_type=='site' && references(^._id)]),
      }[siteCount > 0 ]${additionalFilter},
}`
}
