import { FC, useEffect, useState } from 'react'
import { SiteConfiguration } from '@the-headless-club/models'
import { styled } from '@the-headless-club/styles'
import { Button, Container, Link, Media } from '@the-headless-club/components'
import { useTranslation } from 'react-i18next'
import { CloseIcon } from '@the-headless-club/icons'
import { useRouter } from 'next/dist/client/router'
import { LinkPayloadRegular } from '@the-headless-club/models'

type Props = {
  header: SiteConfiguration['header']
}

const Wrap = styled('div', {
  backgroundColor: '$alabaster',
})
const StyledContainer = styled(Container, {
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$10',
  paddingTop: '$9',
  paddingBottom: '$0',
  gridTemplateColumns: '1fr 70px',
  '@lg': {
    gridTemplateColumns: 'auto 1fr',
    paddingBottom: '$9',
  },
})
const LogoWrapper = styled(Link, {
  width: '100%',
  maxWidth: 240,
  '@lg': {
    width: 375,
  },
})
const DesktopNav = styled('nav', {
  display: 'none',

  '@lg': {
    display: 'block',
  },
})

const NavigationWrap = styled('ul', {
  display: 'flex',
  justifyContent: 'flex-end',
  gridAutoFlow: 'column',
  gap: '$6',
})
const NavigationItem = styled('li', {
  display: 'grid',
  gridGap: '$6',
})

const MenuButton = styled(Button, {
  justifySelf: 'end',
  zIndex: '$header',
  width: 71,
})

const MobileNavWrapper = styled('div', {
  top: 60,
  right: 0,
  position: 'absolute',
  zIndex: '$content',
  visibility: 'hidden',
  opacity: 0,
  variants: {
    isVisible: {
      true: {
        visibility: 'visible',
        opacity: 1,
        transition: 'opacity 0.1s 0s, visibility 0s 0s',
      },
    },
  },
})
const MobileNavList = styled('ul', {
  display: 'grid',
  justifyItems: 'flex-end',
  gap: '$5',
})

const MobileNavItem = styled('li', {
  transform: 'translateY(-100%)',
  transition: 'all 0.3s cubic-bezier(.47,1.64,.41,.8)',

  variants: {
    isVisible: {
      true: {
        transform: 'translateY(0%)',
        transition: 'all 0.3s cubic-bezier(.47,1.64,.41,.8)',
      },
    },
  },
})
const MobileNav = styled('div', {
  position: 'relative',
  display: 'grid',

  '@lg': {
    display: 'none',
  },
})
export const Header: FC<Props> = ({ header }) => {
  const router = useRouter()
  const { t } = useTranslation('header')
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  useEffect(() => {
    const handleRouteChange = () => {
      // close mobile nav
      setIsMobileMenuOpen(false)
    }
    router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [isMobileMenuOpen, router.events])

  return (
    <Wrap>
      <StyledContainer>
        <LogoWrapper href="/">
          {header?.logo && <Media {...header.logo} />}
        </LogoWrapper>
        <DesktopNav>
          <NavigationWrap>
            {header?.navLinks &&
              header?.navLinks?.length > 0 &&
              header?.navLinks?.map((navItem, index) => {
                const linkPayload = navItem.payload as LinkPayloadRegular
                const isActive = router.asPath === linkPayload.href
                return (
                  <NavigationItem key={`${navItem?.title}-${index}`}>
                    {navItem && navItem?.payload && (
                      <Link
                        appearance={
                          isActive ? 'containedBlackRound' : 'outline'
                        }
                        {...navItem.payload}
                      >
                        {navItem.title}
                      </Link>
                    )}
                  </NavigationItem>
                )
              })}
          </NavigationWrap>
        </DesktopNav>
        <MobileNav>
          <MenuButton
            onClick={() => {
              setIsMobileMenuOpen(!isMobileMenuOpen)
            }}
            appearance={isMobileMenuOpen ? 'containedBlackRound' : 'outline'}
          >
            {isMobileMenuOpen ? <CloseIcon /> : t('menu')}
          </MenuButton>
          <MobileNavWrapper isVisible={isMobileMenuOpen}>
            <nav>
              <MobileNavList>
                {header?.navLinks &&
                  header?.navLinks?.length > 0 &&
                  header.navLinks?.map((navItem, index) => (
                    <MobileNavItem
                      isVisible={isMobileMenuOpen}
                      key={`${navItem.title}-${index}`}
                      css={{ transitionDelay: (index + 1) * 40 + 'ms' }}
                    >
                      {navItem.title && navItem?.payload && (
                        <Link appearance="bigWhiteRound" {...navItem.payload}>
                          {navItem.title}
                        </Link>
                      )}
                    </MobileNavItem>
                  ))}
              </MobileNavList>
            </nav>
          </MobileNavWrapper>
        </MobileNav>
      </StyledContainer>
    </Wrap>
  )
}
