import { FC } from 'react'
import { styled } from '@the-headless-club/styles'
import { ratios } from '../Media/ratios'

const MediaWrap = styled('div', {
  width: '100%',
  aspectRatio: 1 / ratios.landscape2,
  borderRadius: '$r2',
  overflow: 'hidden',
  position: 'relative',
  background: '$alabaster_01',
})

const ProjectCardWrap = styled('div', {
  display: 'grid',
  gap: '$8',
})
const HighPerformanceLabelSkeleton = styled('div', {
  height: 26,
  backgroundColor: '$alabaster_01',
  width: 132,
  borderRadius: '$r3',
})
const ToolsAndTags = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '$4',
})
const ToolSkeleton = styled('div', {
  backgroundColor: '$alabaster_01',
  borderRadius: '$rMax',
  height: 24,
  width: 70,
  variants: {
    rect: {
      true: {
        borderRadius: 0,
        width: 100,
      },
    },
  },
})

const NameSkeleton = styled('div', {
  background: '$alabaster_01',
  height: 20,
  width: 70,
})

const CardHeader = styled('div', {
  display: 'grid',
  alignItems: 'center',
  gridAutoFlow: 'column',
  gridTemplateColumns: '1fr min-content',
  gridGap: '$8',
})

export const SiteCardSkeleton: FC = () => {
  return (
    <ProjectCardWrap>
      <MediaWrap />
      <CardHeader>
        <NameSkeleton />
        <HighPerformanceLabelSkeleton />
      </CardHeader>

      <ToolsAndTags>
        {[...Array(3)].map((el, index) => (
          <ToolSkeleton rect={index === 0} key={`skeleton-tool-${index}`} />
        ))}
      </ToolsAndTags>
    </ProjectCardWrap>
  )
}
