import { createBaseImageProjection } from './../projections/createBaseImageProjection'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@the-headless-club/configs'
import { createPortableTextProjection } from '../projections/createPortableTextProjection'

export const createSectionInfoAboutUsProjection = (localeId: LocaleId) => {
  const PORTABLE_TEXT_PROJECTION = createPortableTextProjection(localeId)
  return groq`{
        _key,
        _type,
        items[]{
          ${coalesceLocaleField('title', localeId)},
          type,
          ${coalesceLocaleField('text', localeId)}[]${PORTABLE_TEXT_PROJECTION},
          avatars[]{
            name,
            avatar${createBaseImageProjection(localeId)}
          }
        }
      }
  `
}
