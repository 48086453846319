import { LocaleId } from '@the-headless-club/configs'
import { groq } from 'next-sanity'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'

const LINK_PAYLOAD_PROJECTION = groq`"payload": payload[0]{
  _type == 'linkPayloadRoute' => {
    "href": select(
      defined(query) => route -> path.current + query,
      route -> path.current
    )
  },
  _type == 'linkPayloadExternal' => {
    "href": url,
    "target":'_blank'
  },
  _type == 'linkPayloadPhone' => {
    "href": "tel:" + phone
  },
  _type == 'linkPayloadEmail' => {
    "href": "mailto:" + email
  },
  _type == 'linkPayloadFile' => {
    "href": file.asset->url + "?dl=",
    "download": true
  },
  _type == 'linkPayloadActionAlert' => {
    "action": {
      "type": _type,
      "payload": {
        alert
      }
    }
  }
}`

export const createLinkProjection = (localeId: LocaleId) => {
  return groq`{
    ${coalesceLocaleField('title', localeId)},
    ${LINK_PAYLOAD_PROJECTION}
  }`
}

export const createLinkRawProjection = () => {
  return groq`{
    ${LINK_PAYLOAD_PROJECTION}
  }`
}
