const FONT_REGULAR = {
  fontFamily: 'ABCMarfa-Regular,Helvetica, sans-serif',
  lineHeight: 1.3,
}

export const fonts = {
  heading01: {
    fontSize: 32,
    ...FONT_REGULAR,
    lin: {
      fontSize: [24, 32],
    },
  },
  heading02: {
    fontSize: 16,
    ...FONT_REGULAR,
  },
  heading03: {
    fontSize: 44,
    ...FONT_REGULAR,
  },
  caps01: {
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 600,
    ...FONT_REGULAR,
  },
  body01: {
    fontSize: 14,
    ...FONT_REGULAR,
  },
  body02: {
    fontSize: 12,
    ...FONT_REGULAR,
  },
  body03: {
    fontSize: 11,
    ...FONT_REGULAR,
  },
  body04: {
    fontSize: 16,
    ...FONT_REGULAR,
  },
  body05: {
    fontSize: 24,
    ...FONT_REGULAR,
  },
  body06: {
    fontSize: 20,
    ...FONT_REGULAR,
    lineHeight: '32px',
  },
}

export type FontType = keyof typeof fonts

export const getFontStyles = (fontType: FontType) => fonts[fontType]
