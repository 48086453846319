import { FC } from 'react'
import { ImageLoader } from 'next/legacy/image'

import {
  SanityImageMediaPayload,
  SanityImageType,
} from '@the-headless-club/models'

import { MediaProps } from '../models/MediaProps'
import { getMediaHeightByRatio } from '../helpers/getMediaHeightByRatio'
import { ResponsiveMediaComponent } from './ResponsiveMediaComponent'
import { Image } from './Image'
import { imageUrlBuilder } from '@the-headless-club/services/sanity-service'

type SanityImageProps = Omit<MediaProps, 'mediaPayload'> & {
  mediaPayload: SanityImageMediaPayload
}

type ImageAdapterProps = Omit<MediaProps, 'mediaPayload'> & {
  image: SanityImageType
  blur?: boolean
}

const DEFAULT_IMAGE_QUALITY = 75

const ImageAdapter: FC<ImageAdapterProps> = ({
  alt,
  hardcropRatio,
  hardHotspot,
  objectFit,
  priority,
  sizes,
  layout,
  image,
  blur = false,
}) => {
  if (!image?.asset) {
    return null
  }

  const buildedImage = imageUrlBuilder.image(image)
  /**
   * Prepare image url with transformation i.e. width/height,  crop + focal point
   */
  const loader: ImageLoader = ({ width, quality }) => {
    const height = getMediaHeightByRatio({ width, ratioType: hardcropRatio })

    if (hardHotspot && height) {
      return buildedImage
        .width(width)
        .height(height)
        .fit('crop')
        .focalPoint(...hardHotspot)
        .crop('focalpoint')
        .quality(quality || DEFAULT_IMAGE_QUALITY)
        .auto('format')
        .url()
    }

    if (hardHotspot) {
      return buildedImage
        .width(width)
        .focalPoint(...hardHotspot)
        .quality(quality || DEFAULT_IMAGE_QUALITY)
        .auto('format')
        .url()
    }

    if (height) {
      return buildedImage
        .width(width)
        .height(height)
        .quality(quality || DEFAULT_IMAGE_QUALITY)
        .auto('format')
        .url()
    }

    return buildedImage
      .width(width)
      .quality(quality || DEFAULT_IMAGE_QUALITY)
      .auto('format')
      .url()
  }
  const height = getMediaHeightByRatio({
    width: image.width,
    ratioType: hardcropRatio,
    height: image.height,
  })

  const url = (() => {
    try {
      return buildedImage.url()
    } catch {
      return undefined
    }
  })()

  if (!url) {
    return null
  }

  return (
    <Image
      alt={alt}
      objectFit={objectFit}
      priority={priority}
      sizes={sizes}
      layout={layout}
      src={url}
      placeholder={blur ? 'blur' : 'empty'}
      width={image.width}
      height={height}
      loader={loader}
    />
  )
}

export const SanityImage: FC<SanityImageProps> = ({
  mediaPayload,
  ...restOfProps
}) => {
  const { image, mobileImage } = mediaPayload

  return (
    <ResponsiveMediaComponent
      mobileComponent={
        mobileImage && <ImageAdapter image={mobileImage} {...restOfProps} />
      }
      component={<ImageAdapter image={image} {...restOfProps} />}
    />
  )
}
