import { createSiteListProjection } from '../projections/createSiteListProjection'
import { groq } from 'next-sanity'
import { ITEMS_PER_PAGE, LocaleId } from '@the-headless-club/configs'

export const createSectionSiteListProjection = (localeId: LocaleId) => {
  return groq`{
        _key,
        _type,
        "start":0,
        "totalCount":count(*[_type=='site']),
        "limit":${ITEMS_PER_PAGE},
        "items": ${createSiteListProjection(
          localeId,
          "*[_type=='site'] | order(orderRank desc)",
          `[0...${ITEMS_PER_PAGE}]`,
        )},
        "filters":[
          {
            "label":"Performance",
            "name":"performance",
            "type": "performance-toggle",
            "values":[{"name":'High Performance'}],
          },
          {
            "label":"Category",
            "name":"category",
            "type": "dropdown",
            "values":*[_type=='tag' && count(*[_type=='site' && references(^._id)]) > 0][]{name} | order(name),
          },
          {
            "label": "Platform",
            "name": "platform",
            "type": "dropdown",
            "values":  *[_type=='tool' && toolCategory->name=='Ecommerce Platform' && count(*[_type=='site' && references(^._id)]) > 0][]{name} | order(name),
          },
          {
            "label": "CMS",
            "name": "cms",
            "type": "dropdown",
            "values": *[_type=='tool' && toolCategory->name=='Headless CMS' && count(*[_type=='site' && references(^._id)]) > 0][]{name} | order(name),
          },
          {
            "label": "FE Framework",
            "name": "feframework",
            "type": "dropdown",
            "values":  *[_type=='tool' && toolCategory->name=='Frontend framework' && count(*[_type=='site' && references(^._id)]) > 0][]{name} | order(name),
          },
        ]

    }
  `
}
