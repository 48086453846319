import { groq } from 'next-sanity'
import { getClient } from './getClient'
import { captureException } from '@sentry/nextjs'

const sanityClient = getClient(false)

export const getSiteUrl = async (
  id: string,
): Promise<{
  _id: string
  url: string
} | null> => {
  try {
    return await sanityClient.fetch<{
      _id: string
      url: string
    }>(groq`*[_type == 'site' && _id == $id][0]{_id, url}`, { id })
  } catch (e) {
    captureException(e)
    return null
  }
}
