import { FC } from 'react'
import { DotGreen, DotGrey } from '@the-headless-club/icons'
import { styled } from '@the-headless-club/styles'
import { MetricValue } from 'crux-api'
import { checkPerformanceMetric } from './helpers/checkPerformanceMetric'
import { useTranslation } from 'react-i18next'

const S_TO_MS = 1000
const MAX_MS_VALUE = 10

const Description = styled('div', {
  projectFont: 'body01',
  gridColumn: '2',
})

type PerformanceMetricLabelProps = {
  metricValue: MetricValue
  type:
    | 'largest_contentful_paint'
    | 'first_input_delay'
    | 'cumulative_layout_shift'
    | 'first_contentful_paint'
}

const fixMetricValueType = (value: string | number) => {
  return parseFloat(value.toString())
}

export const PerformanceMetricLabel: FC<PerformanceMetricLabelProps> = ({
  metricValue,
  type,
}) => {
  const isPerformanceMetricHigh = checkPerformanceMetric(metricValue)
  const p75 = fixMetricValueType(metricValue.percentiles.p75)
  const { t } = useTranslation('performanceMetrics')

  const seconds = p75 < MAX_MS_VALUE ? p75 : (p75 / S_TO_MS).toFixed(1)
  const unit =
    type !== 'cumulative_layout_shift'
      ? p75 < MAX_MS_VALUE
        ? ' ms'
        : ' s'
      : ''

  return (
    <>
      {isPerformanceMetricHigh ? <DotGreen /> : <DotGrey />}
      <Description>{`${t(type)} (${seconds}${unit})`}</Description>
    </>
  )
}
