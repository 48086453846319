import { groq } from 'next-sanity'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'

export const sanityImageProjection = (fieldName: string) => groq`{
  ...${fieldName},
  ...${fieldName}.asset -> {
    "width": metadata.dimensions.width,
    "height": metadata.dimensions.height 
  }
}`

const muxVideoProjection = (fieldName: string) => groq`{
  "width": ${fieldName}.asset->data.static_renditions.files[-1].width,
  "height": ${fieldName}.asset->data.static_renditions.files[-1].height,
  "hls": "https://stream.mux.com/" + ${fieldName}.asset->data.playback_ids[0].id + ".m3u8",
  "mp4": "https://stream.mux.com/" + ${fieldName}.asset->data.playback_ids[0].id + "/" + ${fieldName}.asset->data.static_renditions.files[-1].name,
  "aspectRatio": ${fieldName}.asset->data.aspect_ratio,
  "duration": ${fieldName}.asset->data.duration,
  "firstFrame": "https://image.mux.com/" + ${fieldName}.asset->data.playback_ids[0].id + "/thumbnail.jpg?time=0",
  "id": ${fieldName}.asset->data.id,
}`

export const createMediaProjection = (localeId: string) => groq`{
    ${coalesceLocaleField('alt', localeId)},
    mediaType == 'image' => {
      "mediaPayload": {
        "type": "sanity-image",
        "image": ${sanityImageProjection('image')},
        overrideMobileImage && defined(mobileImage) => {
          "mobileImage": ${sanityImageProjection('mobileImage')}
        }
      }
    },
    mediaType == 'video' => {
      "mediaPayload": {
        "video": video,
        "type": "mux-video",
        "video": ${muxVideoProjection('video')},
        "mobileVideo": ${muxVideoProjection('mobileVideo')}
      }
    },
   }`
