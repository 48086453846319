import { FC, PropsWithChildren } from 'react'
import { styled } from '@the-headless-club/styles'
import { PerformanceBolt } from '@the-headless-club/icons'
import { useTranslation } from 'react-i18next'

const PerformanceMetricWrap = styled('div', {
  projectFont: 'body03',
  display: 'grid',
  background: 'linear-gradient(to right, #53F0C9, #7FF392)',
  columnGap: '$5',
  rowGap: '$5',
  gridTemplateColumns: 'auto 1fr',
  alignItems: 'center',
  padding: '$4 $6',
  borderRadius: '$r3',
})

const Description = styled('div', {
  projectFont: 'body01',
  gridColumn: '2',
  color: '$gray400',
})

const Label = styled('span', {
  whiteSpace: 'nowrap',
  display: 'inline-block',
  color: '$almost_black',
})

const GradientLine = styled('div', {
  background: 'linear-gradient(to right, #53F0C9, #7FF392)',
  width: '100%',
  height: '2px',
  gridColumn: 'span 2',
  margin: '12px 0 20px 0',
})

type HighPerformanceLabelProps = {
  isDescriptionVisible?: boolean
  isHighPerformance?: boolean
}

const AlmostBlackPerformanceBolt: FC = () => {
  return (
    <svg
      width="9"
      height="12"
      viewBox="0 0 16 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 12.5H7.39062L4.99531 21.5L15.5 9.5H8.60938L11 0.5L0.5 12.5Z"
        fill="#191919"
      />
    </svg>
  )
}

export const HighPerformanceLabel: FC<
  PropsWithChildren<HighPerformanceLabelProps>
> = ({ isDescriptionVisible = false, isHighPerformance }) => {
  const { t } = useTranslation('performanceMetrics')

  if (!isHighPerformance) return null

  if (isDescriptionVisible) {
    return (
      <>
        <PerformanceBolt />
        <Label>{t('highPerformance')}</Label>
        <Description>{t('highPerformanceDescription')}</Description>
        <GradientLine />
      </>
    )
  }

  return (
    <PerformanceMetricWrap>
      <AlmostBlackPerformanceBolt />
      <Label>{t('highPerformance')}</Label>
    </PerformanceMetricWrap>
  )
}
