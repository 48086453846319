import { createBaseImageProjection } from '../projections/createBaseImageProjection'
import { groq } from 'next-sanity'
import { LocaleId } from '@the-headless-club/configs'
import { createPerfMetricProjection } from './createPerfMetricsProjection'

export const createSiteCardProjection = (localeId: LocaleId) => {
  const BASE_IMAGE_PROJECTION = createBaseImageProjection(localeId)
  const PERF_METRIC_PROJECTION = createPerfMetricProjection()

  return groq`{
      "allFilters":[
        ...tags[]->.name,
        FEFramework->.name,
        ECPlatform->.name,
        CMS->.name,
        ...tools[]->.name,
      ],
      name,
      "path":path.current,
      url,
      perfMetric${PERF_METRIC_PROJECTION},
      tags[]->{
        name,
        "path":path.current,
      },
      "allTools":[
        FEFramework->{
          name,
          "path":path.current,
        },
        ECPlatform->{
          name,
          "path":path.current,
        },
        CMS->{
          name,
          "path":path.current,
        },
        ...tools[]->{
        name,
        "path":path.current,
      },
    ],
    "highPerformance":count([
      perfMetric.mobile.first_input_delay,
      perfMetric.mobile.cumulative_layout_shift,
      perfMetric.mobile.first_contentful_paint,
      perfMetric.mobile.largest_contentful_paint,
    ][percentiles.p75 < histogram[0].end]) >= 3,
    desktopMediaList[]${BASE_IMAGE_PROJECTION}
    }
  `
}
