import React, { FC } from 'react'

type Props = {
  className?: string
  size?: number
  fill?: string
}

export const GoToArrow: FC<Props> = ({
  className,
  size = 9,
  fill = 'black',
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 9 9"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.01943 1.68563H2.9621V0.352295H8.29543V5.68563H6.96209V2.62829L0.942693 8.64772L2.74326e-05 7.70505L6.01943 1.68563Z"
        fill={fill}
      />
    </svg>
  )
}
