import React from 'react'
import NextImage, { ImageProps } from 'next/legacy/image'

export const Image: React.FunctionComponent<ImageProps> = ({
  layout,
  alt,
  width,
  src,
  height,
  objectFit,
  sizes,
  priority,
  placeholder,
  loader,
  blurDataURL,
  objectPosition,
}) => {
  const isFillLayout = layout === 'fill'
  return (
    <NextImage
      src={src}
      width={isFillLayout ? undefined : width}
      height={isFillLayout ? undefined : height}
      alt={alt}
      loader={loader}
      layout={layout}
      sizes={sizes}
      placeholder={placeholder}
      blurDataURL={blurDataURL}
      priority={priority}
      objectFit={isFillLayout ? objectFit ?? 'cover' : undefined}
      className={'Image'}
      objectPosition={objectPosition}
    />
  )
}
