import { FC, ReactElement } from 'react'
import { styled } from '@the-headless-club/styles'

export const ResponsiveMediaWrapper = styled('div', {
  position: 'relative',
  width: '100%',
  height: '100%',
  variants: {
    visible: {
      always: {
        display: 'block',
        '@md': {
          display: 'block',
        },
      },
      onlyMobile: {
        display: 'block',
        '@md': {
          display: 'none',
        },
      },
      onlyDesktop: {
        display: 'none',
        '@md': {
          display: 'block',
        },
      },
    },
  },
})

type Props = {
  mobileComponent?: ReactElement
  component: ReactElement
}

export const ResponsiveMediaComponent: FC<Props> = ({
  mobileComponent,
  component,
}) => {
  return (
    <>
      <ResponsiveMediaWrapper
        visible={mobileComponent ? 'onlyDesktop' : 'always'}
      >
        {component}
      </ResponsiveMediaWrapper>
      {mobileComponent && (
        <ResponsiveMediaWrapper visible={'onlyMobile'}>
          {mobileComponent}
        </ResponsiveMediaWrapper>
      )}
    </>
  )
}
