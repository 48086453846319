import { FC, PropsWithChildren } from 'react'
import NextLink, { LinkProps } from 'next/link'
import {
  StyledClickable,
  StyledClickableProps,
} from '../StyledClickable/StyledClickable'
import { LinkAction } from './LinkAction'
import { LinkActions } from '@the-headless-club/models'

type ProjectLinkProps = PropsWithChildren<
  StyledClickableProps & {
    href?: LinkProps['href']
    shallow?: boolean
    target?: '_blank'
    className?: string
    download?: boolean
    action?: LinkActions
  }
>

export const Link: FC<ProjectLinkProps> = ({
  children,
  download,
  appearance,
  href,
  tabIndex,
  target,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  className,
  action,
}) => {
  const elementProps = {
    download,
    onClick,
    onMouseEnter,
    onMouseLeave,
    onFocus,
    tabIndex,
    target,
    className,
  }
  const stylingProps = {
    appearance,
  }

  if (href && action)
    throw new Error('Link cannot take both href and action props')

  if (href)
    return (
      <NextLink href={href} passHref legacyBehavior>
        <StyledClickable {...stylingProps} {...elementProps} as={'a'}>
          {children}
        </StyledClickable>
      </NextLink>
    )

  if (action)
    return (
      <LinkAction
        action={action}
        renderButton={(actionProps) => (
          <StyledClickable
            {...stylingProps}
            {...elementProps}
            {...actionProps}
            as={'button'}
          >
            {children}
          </StyledClickable>
        )}
      />
    )

  return null
}
