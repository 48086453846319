import { ITEMS_PER_PAGE } from '@the-headless-club/configs'
import { Site, SitesFilterNameType } from '@the-headless-club/models'

import { getLocaleRegionIdFromPath } from '@the-headless-club/utils'
import { useRouter } from 'next/dist/client/router'
import { useEffect, useState } from 'react'
import { getClient } from './getClient'
import { createFilteredSitesProjection } from './projections/createFilteredSitesProjection'

const filterCategories: SitesFilterNameType[] = [
  'category',
  'platform',
  'cms',
  'feframework',
]

export const getSites = (
  allSites: Site[],
  limit = ITEMS_PER_PAGE,
  initialTotalCount = ITEMS_PER_PAGE,
  currentPage = 1,
) => {
  const { query } = useRouter()
  const [filteredSites, setFilteredSites] = useState<Site[] | undefined>(
    allSites,
  )
  const [totalCount, setTotalCount] = useState<number>(initialTotalCount)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const sitesIndexStart = currentPage === 1 ? 0 : (currentPage - 1) * limit
  const sitesIndexEnd = currentPage * limit
  const searchValue = Array.isArray(query.v) ? query.v[0] : query.v || ''
  const searchString =
    searchValue &&
    `allFilters match "*${searchValue}*" || name match "*${searchValue}*"`

  const createFilterGroupString = (
    groupFilterValues: string[] | string | undefined,
  ) => {
    if (!groupFilterValues) {
      return ''
    }
    if (typeof groupFilterValues === 'string') {
      return `allFilters match "${groupFilterValues}"`
    }
    return groupFilterValues.reduce(
      (acc, cur, index) =>
        acc +
        `allFilters match "${cur}"${
          groupFilterValues?.length !== index + 1 ? ' || ' : ')'
        }`,
      '(',
    )
  }

  const filterString = filterCategories
    .filter((filterGroupName) => query[filterGroupName])
    .map((filterGroupName) => createFilterGroupString(query[filterGroupName]))
    .join(' && ')

  const fetchSanity = async (query: string) => {
    const data = await getClient(false).fetch(query)
    setFilteredSites(data.filteredSites)
    setTotalCount(data.totalCount)
    setIsLoading(false)
  }

  const highPerformanceFilterString = (() => {
    const performance = query['performance']
    if (performance === 'High Performance') {
      return 'highPerformance'
    }
    return ''
  })()

  useEffect(() => {
    if (Object.keys(query).length !== 0) {
      setIsLoading(true)
      const [localeId] = getLocaleRegionIdFromPath()

      const FILTERED_SITES_LIST_QUERY = createFilteredSitesProjection(
        localeId,
        `[${highPerformanceFilterString}][${filterString}][${searchString}]`,
        `[${sitesIndexStart}...${sitesIndexEnd}]`,
      )
      fetchSanity(FILTERED_SITES_LIST_QUERY)
    }
  }, [filterString, currentPage, JSON.stringify(query)])

  return [
    Object.keys(query).length === 0 ? allSites : filteredSites,
    Object.keys(query).length === 0 ? initialTotalCount : totalCount,
    isLoading,
  ] as const
}
