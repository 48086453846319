import { FC } from 'react'
import { PerfMetricType } from '@the-headless-club/models'
import { styled } from '@the-headless-club/styles'
import { HighPerformanceLabel } from './HighPerformanceLabel'
import { PerformanceMetricLabel } from './PefromanceMetricLabel'
import { useTranslation } from 'react-i18next'

const Wrap = styled('div', {
  projectFont: 'body01',
  display: 'grid',
  gap: '$9',
})

const Root = styled('div', {
  display: 'grid',
  gap: '$5',
})

const PerformanceTitle = styled('h3', {
  projectFont: 'caps01',
})

const GradientContainer = styled('div', {
  borderRadius: '$r2',
  padding: '2px',
  border: '2px double transparent',
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
  variants: {
    isPerformanceHigh: {
      true: {
        backgroundImage:
          'linear-gradient($alabaster, $alabaster), linear-gradient(to right, #53F0C9, #7FF392)',
      },
      false: {
        backgroundImage:
          'linear-gradient($alabaster, $alabaster), linear-gradient(to right, #F2EFE9, #F2EFE9)',
      },
    },
  },
})

const PlainBackgroundContainer = styled('div', {
  backgroundColor: '$alabaster',
  borderRadius: '$r2',
  padding: '24px 20px',
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  alignItems: 'center',
  gap: '$5 $7',
  svg: {
    placeSelf: 'center',
  },
})

const DateContainer = styled('div', {
  projectFont: 'body03',
  textAlign: 'right',
  color: '$gray300',
})

type PerformanceMetricsDetailsProps = {
  perfMetric: PerfMetricType
  highPerformance?: boolean
  date: string
}

export const PerformanceMetricsDetails: FC<PerformanceMetricsDetailsProps> = ({
  perfMetric,
  date,
  highPerformance,
}) => {
  const { t } = useTranslation('performanceMetrics')
  const dateConverted = new Date(date)

  return (
    <Wrap>
      <PerformanceTitle>{t('performanceMetrics')}</PerformanceTitle>
      <Root>
        <GradientContainer isPerformanceHigh={highPerformance}>
          <PlainBackgroundContainer>
            <HighPerformanceLabel
              isDescriptionVisible={true}
              isHighPerformance={highPerformance}
            />
            {perfMetric.largest_contentful_paint && (
              <PerformanceMetricLabel
                type={'largest_contentful_paint'}
                metricValue={perfMetric.largest_contentful_paint}
              />
            )}
            {perfMetric.first_input_delay && (
              <PerformanceMetricLabel
                type={'first_input_delay'}
                metricValue={perfMetric.first_input_delay}
              />
            )}
            {perfMetric.cumulative_layout_shift && (
              <PerformanceMetricLabel
                type={'cumulative_layout_shift'}
                metricValue={perfMetric.cumulative_layout_shift}
              />
            )}
            {perfMetric.first_contentful_paint && (
              <PerformanceMetricLabel
                type={'first_contentful_paint'}
                metricValue={perfMetric.first_contentful_paint}
              />
            )}
          </PlainBackgroundContainer>
        </GradientContainer>
        {date && (
          <DateContainer>
            {`${t('lastMeasured')}: `}
            {dateConverted.toLocaleDateString('en-GB', {
              day: '2-digit',
              month: 'short',
              year: 'numeric',
            })}
          </DateContainer>
        )}
      </Root>
    </Wrap>
  )
}
