import React, { FC } from 'react'

export const ArrowDown: FC = () => {
  return (
    <svg
      width="9"
      height="7"
      viewBox="0 0 9 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.50952 0.517883L4.74365 5.30438L8.10712 0.517883"
        stroke="#191919"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  )
}
