import { FC } from 'react'
import { SiteConfiguration } from '@the-headless-club/models'
import { styled } from '@the-headless-club/styles'
import {
  Container,
  Link,
  Media,
  PortableText,
} from '@the-headless-club/components'
import React from 'react'
import { useTranslation } from 'react-i18next'
type Props = {
  footer: SiteConfiguration['footer']
  openNewsletter?: () => void
}

const Wrap = styled('div', {
  backgroundColor: '$alabaster',
  paddingTop: '$9',
  paddingBottom: '$9',
})
const StyledContainer = styled(Container, {
  display: 'grid',
  justifyContent: 'center',
  justifyItems: 'center',
  gridTemplateColumns: '1fr',
  '@lg': {
    gridTemplateColumns: '1fr 1fr 1fr',
    rowGap: '$13',
    columnGap: '$6',
  },
})

const NavigationWrap = styled('ul', {
  marginTop: '$8',
  display: 'flex',
  projectFont: 'body01',
  alignItems: 'center',
  gap: '$6',
  '@lg': {
    marginTop: 0,
    gridColumn: 3,
    gridRow: 2,
    justifySelf: 'flex-end',
  },
})
const NavigationItem = styled('li', {
  display: 'grid',
  gridGap: '$6',
  projectFont: 'body01',
  color: '$almost_black',
})

const OpenNewsletterBtn = styled('button', {
  projectFont: 'body01',
  color: '$almost_black',
  background: 'transparent',
  '&:focus-visible': {
    outline: '2px solid $black',
    outlineOffset: '2px',
  },
})

const LogoWithSocials = styled('div', {
  display: 'grid',
  gap: '$10',
  '@lg': {
    gridColumn: 2,
    gridRow: 1,
  },
})
const SocialWrap = styled('div', {
  width: 24,
  height: 24,
})
const Socials = styled('div', {
  display: 'grid',
  gridAutoFlow: 'column',
  gap: '$10',
})
const LogoWrapper = styled(Link, {
  width: 85,
  height: 85,
  gridRow: 1,
  justifySelf: 'center',
  cursor: 'pointer',
})
const AdditonalCaption = styled('div', {
  projectFont: 'body01',
  color: '$almost_black',
  display: 'flex',
  alignItems: 'center',
  gap: '$5',
  '@lg': {
    gridRow: 2,
    gridColumn: 1,
    justifySelf: 'flex-start',
  },
})
const MainCaption = styled('div', {
  projectFont: 'body01',
  color: '$almost_black',
  marginTop: '$13',
  marginBottom: '$10',
  '@lg': {
    margin: 0,
    gridRow: 2,
    gridColumn: 2,
  },
})

const Dot = styled('div', {
  width: 2,
  height: 2,
  background: '$almost_black',
})

export const Footer: FC<Props> = ({ footer, openNewsletter }) => {
  const { t } = useTranslation('footer')
  return (
    <Wrap>
      <StyledContainer>
        <LogoWithSocials>
          {footer?.logoIcon && (
            <LogoWrapper href="/">
              <Media {...footer?.logoIcon} />
            </LogoWrapper>
          )}
          <Socials>
            {footer?.socials &&
              footer?.socials?.length > 0 &&
              footer?.socials?.map((social, index) => (
                <SocialWrap key={`social-${index}`}>
                  <Link
                    target="_blank"
                    appearance="grid"
                    {...social?.ctaLink?.payload}
                  >
                    <Media {...social.icon} />
                  </Link>
                </SocialWrap>
              ))}
          </Socials>
        </LogoWithSocials>
        <MainCaption>
          {footer?.mainCaption && <PortableText value={footer.mainCaption} />}
        </MainCaption>
        <AdditonalCaption>{footer?.additionalCaption}</AdditonalCaption>
        {footer?.navLinks && (
          <NavigationWrap>
            <NavigationItem>
              <OpenNewsletterBtn onClick={openNewsletter}>
                {t('newsletter')}
              </OpenNewsletterBtn>
            </NavigationItem>
            <Dot />
            {footer?.navLinks &&
              footer.navLinks.length > 0 &&
              footer.navLinks?.map((navItem, index) => (
                <React.Fragment key={`${navItem.title}-${index}`}>
                  <NavigationItem>
                    <Link appearance="grid" {...navItem.payload}>
                      {navItem.title}
                    </Link>
                  </NavigationItem>
                  {footer.navLinks?.length !== index + 1 && <Dot />}
                </React.Fragment>
              ))}
          </NavigationWrap>
        )}
      </StyledContainer>
    </Wrap>
  )
}
