import { MediaRatiosType, ratios } from '../ratios'

type Props = {
  width: number
  ratioType?: MediaRatiosType
  height?: number
}

export const getMediaHeightByRatio = ({ width, ratioType, height }: Props) => {
  const hardCropValue = ratioType ? ratios[ratioType] : undefined
  if (hardCropValue) {
    return Math.floor(width * hardCropValue)
  }
  return height ?? undefined
}
