import { SitesFilterNameType } from '@the-headless-club/models'
import { GTMResetSearch } from './gtmResetSearch'

export const GTMSearchResults = (
  searchParams: Record<SitesFilterNameType | 'search_term', string | string[]>,
) => {
  if (!searchParams) {
    return
  }

  const parsedSearchParams = Object.entries(searchParams).reduce(
    (acc, [key, value]) => {
      if (key === 'search_term') {
        return { ...acc, [key]: value }
      }

      return { ...acc, [`q_${key}`]: value }
    },
    {},
  )

  GTMResetSearch()

  const w = window as any

  w.dataLayer = w.dataLayer || []
  w.dataLayer.push({
    event: 'view_search_results',
    search: {
      ...parsedSearchParams,
    },
  })
}
