import { createBaseImageProjection } from './projections/createBaseImageProjection'
import { getLocaleRegionIdFromPath } from '@the-headless-club/utils'
import { groq } from 'next-sanity'
import { getClient } from './getClient'
import { SiteConfiguration } from '@the-headless-club/models'
import { createSEOProjection } from './projections/createSEOProjection'
import { DEFAULT_REGION_ID } from '@the-headless-club/configs'
import { createLinkProjection } from './projections/createLinkProjection'
import { coalesceLocaleField } from './helpers/coalesceLocaleField'
import { createPortableTextProjection } from './projections/createPortableTextProjection'

export const getSiteConfiguration = async (
  localeRegionString: string,
  preview = false,
): Promise<SiteConfiguration> => {
  const [regionId, localeId] = getLocaleRegionIdFromPath(localeRegionString)
  const LINK_PROJECTION = createLinkProjection(localeId)
  const BASE_IMAGE_PROJECTION = createBaseImageProjection(localeId)
  const PORTABLE_TEXT_PROJECTION = createPortableTextProjection(localeId)

  const seoQuery = groq`
    *[_type == "seo"]${createSEOProjection(localeId)}[0]
  `
  const headerQuery = groq`
  *[_type == "header"] {
    "headerNavigation": coalesce(headerNavigation_${regionId}, headerNavigation_${DEFAULT_REGION_ID}) -> {
      ${coalesceLocaleField('caption', localeId)},
      logo${BASE_IMAGE_PROJECTION},
      navLinks[]${LINK_PROJECTION},
    }
  }[0].headerNavigation
`
  const footerQuery = groq`
  *[_type == "footer"] {
    "footerNavigation": coalesce(footerNavigation_${regionId}, footerNavigation_${DEFAULT_REGION_ID}) -> {
      ${coalesceLocaleField(
        'mainCaption',
        localeId,
      )}[]${PORTABLE_TEXT_PROJECTION},
      ${coalesceLocaleField('additionalCaption', localeId)},
      logoIcon${BASE_IMAGE_PROJECTION},
      navLinks[]${LINK_PROJECTION},
      socials[] {
        icon${BASE_IMAGE_PROJECTION},
        ctaLink${LINK_PROJECTION},
      },
    }
  }[0].footerNavigation
`

  const modalNewsletterQuery = groq`
    *[_type == "modalNewsletter"] {
        ${coalesceLocaleField('newsletterHeading', localeId)},
        triggerRouteNumber,
        timeToTrigger
      }[0]
    `

  const siteConfigurationQuery = groq`{
      "header": ${headerQuery},
      "footer": ${footerQuery},
      "modalNewsletter": ${modalNewsletterQuery},
      "seo": ${seoQuery},
    }
  `

  const siteConfigurationData = await getClient(preview).fetch(
    siteConfigurationQuery,
  )
  return {
    ...siteConfigurationData,
  }
}
