import { FC } from 'react'

export const PerformanceBolt: FC = () => {
  return (
    <svg
      width="16"
      height="22"
      viewBox="0 0 16 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 12.5H7.39062L4.99531 21.5L15.5 9.5H8.60938L11 0.5L0.5 12.5Z"
        fill="url(#paint0_linear_541_107)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_541_107"
          x1="8"
          y1="0.5"
          x2="8"
          y2="21.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#89F384" />
          <stop offset="1" stopColor="#3DEEE3" />
        </linearGradient>
      </defs>
    </svg>
  )
}
