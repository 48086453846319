export const newsletterSubscribe = async (email: string) => {
  return await fetch('/api/newsletter/subscribe', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(email),
  })
}

export const checkSubscription = async (email: string) =>
  await fetch('/api/newsletter/check-subscription', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(email),
  })
