import { forwardRef, useState, FormEvent, useEffect } from 'react'
import { styled } from '@the-headless-club/styles'
import { useTranslation } from 'react-i18next'
import { Button, Loader } from '@the-headless-club/components'
import { captureException, captureMessage } from '@sentry/nextjs'
import {
  newsletterSubscribe,
  checkSubscription,
} from '@the-headless-club/services/api-service'
import { Error } from '@the-headless-club/icons'

const Root = styled('form', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
})

const InputWrap = styled('div', {
  width: '100%',
  height: '$11',
  display: 'grid',
  gap: '$5',
  gridTemplateColumns: '1fr 120px',
  borderBottom: '2px solid',
})

const InputEmail = styled('input', {
  width: '100%',
  height: '100%',
  border: 'none',
  projectFont: 'body04',
  padding: '0',
  backgroundColor: '$alabaster',
})

const StyledButton = styled(Button, {
  textTransform: 'uppercase',
  margin: '$4 0',
  transition: '0.4s opacity',
})

const HintText = styled('p', {
  display: 'grid',
  gridTemplateColumns: 'min-content max-content',
  gap: '$2',
  alignItems: 'center',
  projectFont: 'body02',
  marginTop: '$5',
  width: '100%',
  transition: 'opacity 200ms',

  variants: {
    appearance: {
      error: {
        color: '$errorRed',
        visibility: 'visible',
        opacity: 1,
      },
      hidden: {
        visibility: 'hidden',
        opacity: 0,
      },
    },
  },
})

const SuccessInfo = styled('p', {
  gridColumn: '1/3',
  projectFont: 'body04',
  textAlign: 'center',
  alignSelf: 'center',
})

type Props = {
  className?: string
}

type FormStateTypes =
  | 'default'
  | 'success'
  | 'error.general'
  | 'error.alreadyExist'
  | 'error.emailRequired'

export const NewsletterForm = forwardRef<HTMLFormElement, Props>(
  ({ className }, ref) => {
    const { t } = useTranslation('newsletter')

    const [emailValue, setEmailValue] = useState('')
    const [formState, setFormState] = useState<FormStateTypes>('default')
    const [isSubmited, setIsSubmited] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const handleInputEmailChange = (e: FormEvent<HTMLInputElement>) => {
      setEmailValue(e.currentTarget.value)
    }

    useEffect(() => {
      if (!emailValue) {
        setFormState('error.emailRequired')
        return
      }
      setFormState('default')
    }, [emailValue])

    const submitForm = async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      try {
        setIsSubmited(true)
        setIsLoading(true)

        const checkRes = await checkSubscription(emailValue)

        if (checkRes.ok) {
          setFormState('error.alreadyExist')
          setIsLoading(false)
          return
        }

        const res = await newsletterSubscribe(emailValue)

        const data = await res.json()
        if (!res.ok || data?.errors) {
          captureException('Newsletter subscription error')
          data?.errors?.map((error: any) => {
            error.detail && captureException(error.detail)
          })
          setFormState('error.general')
          setIsLoading(false)
          return
        }

        setFormState('success')
        setIsLoading(false)
      } catch (e) {
        setFormState('error.general')
        setIsLoading(false)
        captureMessage('Newsletter subscription failed')
        captureException(e)
      }
    }

    return (
      <Root ref={ref} className={className} onSubmit={submitForm}>
        <InputWrap>
          {formState === 'success' && isSubmited ? (
            <SuccessInfo>{t(formState)}</SuccessInfo>
          ) : (
            <>
              <InputEmail
                placeholder={t('enterEmail')}
                required
                onChange={handleInputEmailChange}
                aria-label={t('enterEmail')}
                value={emailValue}
                type="email"
              />
              <StyledButton
                appearance={'containedBlackRound'}
                size={'medium'}
                type="submit"
                css={{ projectFont: 'body02' }}
              >
                {isLoading ? <Loader /> : t('submitLabel')}
              </StyledButton>
            </>
          )}
        </InputWrap>
        <HintText
          appearance={(() => {
            if (
              formState === 'default' ||
              formState === 'success' ||
              !isSubmited
            ) {
              return 'hidden'
            }
            return 'error'
          })()}
        >
          <Error /> {t(formState)}
        </HintText>
      </Root>
    )
  },
)
