import React, { FC } from 'react'

export const BlueBadge: FC = () => {
  return (
    <svg
      width="123"
      height="53"
      viewBox="0 0 123 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i_841_526)">
        <path
          d="M12.487 36.2787V29.457C12.3592 28.5622 12.3532 27.5246 12.3532 26.5813C12.3532 12.4792 23.8325 1 37.9344 1H85.4185C99.5207 1 111 12.4792 111 26.5813C111 40.6832 99.5207 52.1625 85.4185 52.1625H37.9344C31.1783 52.1625 25.0137 49.5393 20.4219 45.2099L20.0971 44.9038L19.7553 45.1907C16.0963 48.2616 11.3272 50.1561 6.10001 50.1561C4.34756 50.1561 2.62806 49.9507 1 49.5419C7.53833 48.4233 12.487 43.0224 12.487 36.2787Z"
          fill="url(#paint0_linear_841_526)"
        />
        <path
          d="M12.487 36.2787V32.3382C12.487 30.4198 12.3532 28.4997 12.3532 26.5813C12.3532 12.4792 23.8325 1 37.9344 1H85.4185C99.5207 1 111 12.4792 111 26.5813C111 40.6832 99.5207 52.1625 85.4185 52.1625H37.9344C31.1783 52.1625 25.0137 49.5393 20.4219 45.2099L20.0971 44.9038L19.7553 45.1907C16.0963 48.2616 11.3272 50.1561 6.10001 50.1561C4.34756 50.1561 2.62806 49.9507 1 49.5419C7.53833 48.4233 12.487 43.0224 12.487 36.2787Z"
          stroke="#1B2D5E"
          strokeWidth="1.01723"
        />
        <path
          d="M34.9483 3H88.0517C94.6506 3 100 8.59644 100 15.5C100 22.4036 94.6506 28 88.0517 28H34.9483C28.3494 28 23 22.4036 23 15.5C23 8.59644 28.3494 3 34.9483 3Z"
          fill="#D6E7FD"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_841_526"
          x="0"
          y="-2.22124"
          width="123"
          height="54.8923"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-2.71262" />
          <feGaussianBlur stdDeviation="5.42524" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.48 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_841_526"
          />
        </filter>
        <linearGradient
          id="paint0_linear_841_526"
          x1="56.0002"
          y1="1"
          x2="56.0002"
          y2="52.1625"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AFCEF4" />
          <stop offset="1" stopColor="#C2E2FA" />
        </linearGradient>
      </defs>
    </svg>
  )
}
