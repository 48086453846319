import { FC } from 'react'
import { styled } from '@the-headless-club/styles'
import { MuxVideoMediaPayload, MuxVideoType } from '@the-headless-club/models'
import { Image } from './Image'
import { MediaProps } from '../models/MediaProps'
import { ResponsiveMediaComponent } from './ResponsiveMediaComponent'

type MuxVideoProps = Omit<MediaProps, 'mediaPayload'> & {
  mediaPayload: MuxVideoMediaPayload
}

type VideoAdapterProps = Omit<MediaProps, 'mediaPayload'> & {
  video: MuxVideoType
}

const Container = styled('div', {
  position: 'relative',
  background: 'black',
  img: {
    opacity: 0.75,
  },
  variants: {
    layout: {
      fill: {
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        position: 'absolute',
      },
      responsive: {},
    },
  },
})

const VideoElement = styled('video', {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  top: 0,
  left: 0,
})

const VideoAdapter: FC<VideoAdapterProps> = ({
  layout,
  priority,
  video,
  alt,
  loop,
}) => {
  const { width, height, firstFrame, mp4 } = video

  const containerStyles = (() => {
    const paddingBottom = `${(height / width) * 100}%`
    return layout === 'responsive' ? { paddingBottom } : undefined
  })()

  return (
    <Container layout={layout} style={containerStyles}>
      <Image src={firstFrame} priority={priority} layout={'fill'} />
      <VideoElement
        autoPlay
        muted
        loop={loop}
        playsInline
        title={alt}
        src={mp4}
        poster={firstFrame}
      />
    </Container>
  )
}

export const MuxVideo = ({ mediaPayload, ...restOfProps }: MuxVideoProps) => {
  const { video, mobileVideo } = mediaPayload

  return (
    <ResponsiveMediaComponent
      mobileComponent={
        mobileVideo && <VideoAdapter video={mobileVideo} {...restOfProps} />
      }
      component={<VideoAdapter video={video} {...restOfProps} />}
    />
  )
}
