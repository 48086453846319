import { groq } from 'next-sanity'
import { LocaleId } from '@the-headless-club/configs'
import { createSiteCardProjection } from '../projections/createSiteCardProjection'

export const createFilteredSitesProjection = (
  localeId: LocaleId,
  additionalFilter = '',
  sliceOperator = '',
) => {
  return groq`{
    "filteredSites":*[_type=='site'] | order(orderRank desc)${createSiteCardProjection(
      localeId,
    )}${additionalFilter ? additionalFilter : ''}${
    sliceOperator ? sliceOperator : ''
  },
    "totalCount":count(*[_type=='site']${createSiteCardProjection(localeId)}${
    additionalFilter ? additionalFilter : ''
  })
}`
}
