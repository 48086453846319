import { coalesceLocaleField } from './../helpers/coalesceLocaleField'
import { createSectionBaseSiteListProjection } from '../sections/createSectionBaseSiteListProjection'
import { groq } from 'next-sanity'
import { createBaseImageProjection } from './createBaseImageProjection'

export const createToolPageProjection = (localeId: string) => {
  const BASE_IMAGE_PROJECTION = createBaseImageProjection(localeId)
  const SITE_LIST_FILTER =
    "*[_type=='site' && references(^._id)] | order(orderRank desc)"

  return groq`{
      "type":_type,    
      "showFooter": true,
      "pageBuilder": [
        {
          "_type": "sectionHeroLanding",
          "_key": "sectionHeroLanding" + _id,
          "totalCount":count(${SITE_LIST_FILTER}),
          avatar${BASE_IMAGE_PROJECTION},
          url,
          "title":name,
          type,
          toolCategory->{
            name,
          }
        },
        ${createSectionBaseSiteListProjection(localeId, SITE_LIST_FILTER)}
      ],
      "seo":{
        "metaTitle":"The Headless Club - " + name ,
        "ogTitle":"The Headless Club - " + name ,
        ${coalesceLocaleField('shortDescription', localeId, 'metaDescription')},
        ${coalesceLocaleField('shortDescription', localeId, 'ogDescription')},
       "ogImage":logo${BASE_IMAGE_PROJECTION}
      }
    }`
}
