/**
 * Convention for color naming is tailwind-like with a _ suffix describing opacity
 */
export const colors = {
  // greys
  gray100: '#ececec', // derivative of gray500_01 on white bg
  gray200: '#dadada', // derivative of gray500_02 on white bg
  gray300: '#919090', // derivative of gray500_06 on white bg
  gray400: '#6c6b6b', // derivative of gray500_08 on white bg
  gray500: '#474646', // the base gray in the project
  gray500_01: 'rgba(71, 70, 70, 0.1)',
  gray500_02: 'rgba(71, 70, 70, 0.2)',
  gray500_06: 'rgba(71, 70, 70, 0.6)',
  gray500_08: 'rgba(71, 70, 70, 0.8)',
  // whites
  white: '#FFFFFF',
  white_01: 'rgba(255, 255, 255, 0.1)',
  white_02: 'rgba(255, 255, 255, 0.2)',
  white_06: 'rgba(255, 255, 255, 0.6)',
  white_08: 'rgba(255, 255, 255, 0.8)',
  //blacks
  black: '#000',
  black_01: 'rgba(0, 0, 0, 0.1)',
  black_02: 'rgba(0, 0, 0, 0.2)',
  black_025: 'rgba(0, 0, 0, 0.25)',
  black_04: 'rgba(0, 0, 0, 0.4)',
  black_005: 'rgba(0,0,0,0.05)',
  black_002: 'rgba(0,0,0,0.02)',

  //project-specific
  almost_black: '#191919',
  alabaster: '#FFFCF6',
  alabaster_01: '#E5E2DD',

  errorRed: 'rgba(248, 46, 71, 1)',
}

export type Color = keyof typeof colors
