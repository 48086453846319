import * as Sentry from '@sentry/nextjs'

export const logger = {
  error: (message: string | Error | unknown) => {
    if (typeof message === 'string') {
      try {
        throw new Error(message)
      } catch (e) {
        Sentry.captureException(e)
      }
      return
    }

    Sentry.captureException(message)
  },
}
