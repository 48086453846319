// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

const enabledContexts = ['production', 'deploy-preview']

Sentry.init({
  environment: process.env.SENTRY_ENVIRONMENT,
  dsn:
    SENTRY_DSN ||
    'https://7eec8cc34eb646c68474886d438633ce@o1220134.ingest.sentry.io/6599474',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.5,
  enabled: enabledContexts.includes(process.env.SENTRY_ENVIRONMENT),
  integrations: [
    new CaptureConsoleIntegration({
      // array of methods that should be captured
      // "log" not included
      levels: ['info', 'warn', 'error', 'debug', 'assert'],
    }),
  ],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
