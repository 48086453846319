import { Tool } from '@the-headless-club/models'
import { getLocaleRegionIdFromPath } from '@the-headless-club/utils'
import { useRouter } from 'next/dist/client/router'
import { useEffect, useState } from 'react'
import { getClient } from './getClient'
import { createToolListProjection } from './projections/createToolListProjection'

export const getTools = (allTools: Tool[]) => {
  const { query } = useRouter()
  const [filteredTools, setFilteredTools] = useState<Tool[] | undefined>(
    allTools,
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const searchValue = Array.isArray(query.v) ? query.v[0] : query.v || ''
  const searchString = searchValue && `name match "*${searchValue}*"`

  const filterValue = query['category']

  const filterString = (() => {
    if (!filterValue) {
      return ''
    }
    if (typeof filterValue === 'string') {
      return `toolCategory.name match "${filterValue}"`
    }
    return filterValue.reduce(
      (acc, cur, index) =>
        acc +
        `toolCategory.name match "${cur}"${
          filterValue?.length !== index + 1 ? ' || ' : ''
        }`,
      '',
    )
  })()

  const fetchSanity = async (query: string) => {
    const data = await getClient(false).fetch(query)
    setFilteredTools(data.filteredTools)
    setIsLoading(false)
  }

  useEffect(() => {
    if (filterString || searchString) {
      setIsLoading(true)
      const [localeId] = getLocaleRegionIdFromPath()
      const FILTERED_SITES_LIST_QUERY = createToolListProjection(
        localeId,
        `[${filterString}][${searchString}]`,
      )

      fetchSanity(FILTERED_SITES_LIST_QUERY)
    }
  }, [filterString, query])

  return [
    filterString || searchString ? filteredTools : allTools,
    isLoading,
  ] as const
}
