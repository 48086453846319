import { FC, PropsWithChildren } from 'react'
import { styled } from '@the-headless-club/styles'
import { Link } from '../Link/Link'
import {
  PortableText as SanityPortableText,
  PortableTextComponents,
  PortableTextMarkComponentProps,
} from '@portabletext/react'
import { TypedObject } from '@portabletext/types'
import { LinkField, PortableTextBlocks } from '@the-headless-club/models'

type MarkComponent<T extends TypedObject = any> = (
  props: PropsWithChildren<PortableTextMarkComponentProps<T>>,
) => JSX.Element

export type PortableTextProps = {
  value?: PortableTextBlocks
  additionalComponents?: PortableTextComponents
  withStyles?: boolean
}

const linkMark: MarkComponent<LinkField & { _type: string }> = ({
  value,
  children,
}) => {
  return <Link {...value?.payload}>{children}</Link>
}

const PortableTextWrap = styled('div', {
  variants: {
    withStyles: {
      true: {
        '*:last-child': {
          marginBottom: 0,
        },
        '*:first-child': {
          marginTop: 0,
        },
        strong: {
          color: '$blue',
        },
        a: {
          textDecoration: 'underline',
          textUnderlineOffset: '1px',
          '&:hover': {
            opacity: '0.5',
          },
        },
        h1: {
          projectFont: 'heading03',
          marginBottom: '$9',
          marginTop: '$9',
          textAlign: 'center',
          fontWeight: 'bold',
        },
        h2: {
          projectFont: 'heading01',
          marginBottom: '$9',
          marginTop: '$9',
          fontWeight: 'bold',
        },
        h3: {
          projectFont: 'heading02',
          marginBottom: '$9',
          marginTop: '$9',
          fontWeight: 'bold',
        },

        'p, ul, ol': {
          listStyle: 'inside',
          projectFont: 'body01',
          marginBottom: '$9',
          marginTop: '$9',
        },
        'ol > li': {
          listStyleType: 'decimal',
        },
        'ul > li': {
          listStyleType: 'disc',
        },
      },
    },
  },
})

export const PortableText: FC<PortableTextProps> = ({
  value,
  additionalComponents,
  withStyles = true,
}) => {
  const components: PortableTextComponents = {
    ...additionalComponents,
    marks: {
      link: linkMark,
      ...(additionalComponents?.marks ? additionalComponents.marks : {}),
    },
  }

  return value ? (
    <PortableTextWrap withStyles={withStyles}>
      <SanityPortableText value={value} components={components} />
    </PortableTextWrap>
  ) : null
}
