import React, { FC } from 'react'
import { SanityImage } from './components/SanityImage'
import { MuxVideo } from './components/MuxVideo'
import { styled } from '@the-headless-club/styles'
import { MediaProps } from './models/MediaProps'

const MediaFillWrap = styled('div', {
  position: 'relative',
  variants: {
    fill: {
      true: {
        height: '100%',
        width: '100%',
      },
    },
  },
})

export const Media: FC<MediaProps> = ({
  layout = 'responsive',
  objectFit = 'cover',
  sizes,
  priority,
  mediaPayload,
  alt,
  loop,
  hardcropRatio,
  hardHotspot,
}) => {
  return (
    <MediaFillWrap fill={layout === 'fill'}>
      {(() => {
        switch (mediaPayload?.type) {
          case 'sanity-image':
            return (
              <SanityImage
                alt={alt}
                mediaPayload={mediaPayload}
                sizes={sizes}
                objectFit={objectFit}
                priority={priority}
                layout={layout}
                hardcropRatio={hardcropRatio}
                hardHotspot={hardHotspot}
              />
            )
          case 'mux-video':
            return (
              <MuxVideo
                alt={alt}
                mediaPayload={mediaPayload}
                layout={layout}
                priority={priority}
                loop={loop}
              />
            )
          default:
            return <div>Unsupported media source</div>
        }
      })()}
    </MediaFillWrap>
  )
}
