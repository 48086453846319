import { FC } from 'react'
import { LinkActions } from '@the-headless-club/models'

type ActionProps = {
  onClick: () => any
}

type LinkActionProps = {
  action: LinkActions
  renderButton: (actionProps: ActionProps) => JSX.Element
}

export const LinkAction: FC<LinkActionProps> = ({ action, renderButton }) => {
  switch (action.type) {
    case 'linkPayloadActionAlert': {
      const actionProps = {
        onClick: () => alert(action.payload.alert),
      }
      return <>{renderButton(actionProps)}</>
    }
    case 'linkPayloadActionModal': {
      const actionProps = {
        onClick: () => alert(action.payload.modalContent),
      }
      return <>{renderButton(actionProps)}</>
    }
  }
}
