import { AppProps } from 'next/app'
import Head from 'next/head'
import { I18nextProvider } from 'react-i18next'
import { useRouter } from 'next/router'
import { DefaultSeo } from 'next-seo'

import getI18n from '../translations/i18n'
import { FC, useEffect } from 'react'
import Script from 'next/script'
import { Header } from '@the-headless-club/header'
import { Footer } from '@the-headless-club/footer'
import { imageUrlBuilder } from '@the-headless-club/services/sanity-service'
import { GTMSearchResults } from '@the-headless-club/services/gtm-service'
import {
  ModalNewsletter,
  useModalNewsletter,
} from '@the-headless-club/newsletter'

const MyApp: FC<AppProps> = ({ Component, pageProps }) => {
  const { locale, query } = useRouter()

  if (!locale) throw new Error('Locale has not been set')

  const { siteConfiguration } = pageProps

  const { isOpen, handleClose, openNewsletter } = useModalNewsletter(
    siteConfiguration.modalNewsletter,
  )

  useEffect(() => {
    if (query && query['newsletter'] === 'true' && !isOpen) {
      openNewsletter()
    }
  }, [query])

  useEffect(() => {
    const searchParams = {
      ...(query['v'] ? { search_term: query['v'] } : undefined),
      ...(query['category'] ? { category: query['category'] } : undefined),
      ...(query['platform'] ? { platform: query['platform'] } : undefined),
      ...(query['cms'] ? { cms: query['cms'] } : undefined),
      ...(query['feframework']
        ? { feframework: query['feframework'] }
        : undefined),
      ...(query['performance']
        ? { performance: query['performance'] }
        : undefined),
    }

    if (Object.keys(searchParams).length > 0) {
      GTMSearchResults(searchParams)
    }
  }, [query])

  return (
    <I18nextProvider i18n={getI18n(locale)}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
      </Head>
      {siteConfiguration?.seo && (
        <DefaultSeo
          title={siteConfiguration.seo.metaTitle}
          description={siteConfiguration.seo.metaDescription}
          openGraph={{
            title: siteConfiguration.seo.ogTitle,
            description: siteConfiguration.seo.ogDescription,
            images: [
              {
                url:
                  siteConfiguration.seo.ogImage.mediaPayload.image &&
                  imageUrlBuilder
                    .image(siteConfiguration.seo.ogImage.mediaPayload.image)
                    .width(1200)
                    .height(630)
                    .quality(75)
                    .url(),
                width: 1200,
                height: 630,
                alt: siteConfiguration.seo.ogImage?.alt,
              },
            ],
          }}
          twitter={{
            cardType: 'summary_large_image',
          }}
        />
      )}
      {siteConfiguration.header && (
        <Header header={pageProps.siteConfiguration.header} />
      )}
      <Component {...pageProps} />

      <Footer
        footer={pageProps.siteConfiguration.footer}
        openNewsletter={openNewsletter}
      />

      {siteConfiguration?.modalNewsletter && (
        <ModalNewsletter
          newsletterHeading={
            siteConfiguration.modalNewsletter.newsletterHeading
          }
          isOpen={isOpen}
          handleClose={handleClose}
        />
      )}

      <Script
        strategy="afterInteractive"
        id="gtm"
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-N3HBGLS');
          `,
        }}
      />
    </I18nextProvider>
  )
}
export default MyApp
