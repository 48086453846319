import React, { FC } from 'react'

export const SelectedIcon: FC = () => {
  return (
    <svg width="14" height="14">
      <path
        aria-hidden="true"
        d="M12.586 24l-2.829-2.828 1.415-1.415L14 22.586l11.314-11.314 1.414 1.414L14 25.414 12.586 24z"
        fill="black"
      ></path>
    </svg>
  )
}
