import { ContainerSpaceType } from '@the-headless-club/models'
import { lin } from './lin'

export const getContainerSpace = (containerSpaceType: ContainerSpaceType) => {
  switch (containerSpaceType) {
    case 'none':
      return {
        paddingLeft: 0,
        paddingRight: 0,
      }

    case 'small':
      return lin({
        paddingRight: 'S',
        paddingLeft: 'S',
      })

    case 'medium':
      return lin({
        paddingRight: 'L',
        paddingLeft: 'L',
      })

    case 'large':
      return lin({
        paddingRight: 'XL',
        paddingLeft: 'XL',
      })

    default:
      return {}
  }
}
