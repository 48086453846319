import { Site } from '@the-headless-club/models'
import { createClient } from 'next-sanity'
import { config } from '../config'

const client = createClient({
  ...config,
  token: process.env.SANITY_API_PERF_METRICS_TOKEN,
})

export const createOrUpdateSitePerfMetrics = async (
  items: Required<Pick<Site, 'perfMetric' | '_id'>>[],
) => {
  const patches = items
    .filter((item) => !!item._id)
    .map((item) => {
      return {
        patch: {
          id: item._id,
          set: {
            perfMetric: item.perfMetric,
          },
        },
      }
    })

  return await client.mutate(patches)
}
