export const sizes = {
  0: '0px',
  1: '1px',
  2: '2px',
  3: '4px',
  4: '6px',
  5: '8px',
  6: '10px',
  7: '12px',
  8: '16px',
  9: '24px',
  10: '32px',
  11: '48px',
  12: '64px',
  13: '96px',
  14: '128px',
  15: '160px',
  16: '220px',
  17: '280px',
  buttonHeightS: '24px',
  buttonHeightM: '30px',
  buttonHeightL: '75px',
} as const
